<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="plus卡模板"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
       <template #header="params">
        <div class="search_box">
          <el-row :gutter="5" style="padding-top: 5px; padding-bottom: 5px">
            <el-col :span="2" style="text-align: end;">
              会员卡名称：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-input v-model="searchData['cardName']"  style="width: 200px" />
            </el-col>

            <el-col :span="2" style="text-align: end;">
              会员卡类型：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-select clearable  v-model="searchData['cardType']">
                <el-option
                    v-for="(item,index) in cardTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"/>
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :offset="20">
              <el-button @click="resetSearchData">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <router-link to="/plusCard/template/add">
            <el-button type="primary">新建模板</el-button>
          </router-link>
        </div>
      </template>
      <el-table-column label="模板编号" fixed="left" prop="plusTemplateCode" :min-width="180">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="gotoDetail(scope.row)">{{ scope.row.plusTemplateCode }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="会员卡名称" prop="cardName" :min-width="160"/>
      <el-table-column label="会员卡型" prop="cardType" :min-width="160">
        <template slot-scope="scope">
          <div v-if="scope.row.cardType==1">会员卡</div>
          <div v-if="scope.row.cardType==2">超级会员卡</div>
        </template>
      </el-table-column>
      <el-table-column label="金额" prop="cardAmt" :min-width="150">
      </el-table-column>

      <el-table-column label="是否首次免单" prop="isFreeOrder" :min-width="150">
        <template slot-scope="scope">
          <div v-if="scope.row.isFreeOrder==true">是</div>
          <div v-if="scope.row.isFreeOrder==false">否</div>
        </template>
      </el-table-column>
      <el-table-column label="免单限制" prop="freeOrderLimit" :min-width="150">
        <template slot-scope="scope">
          <div v-if="scope.row.isFreeOrder==0">未配置</div>
          <div v-if="scope.row.isFreeOrder==1">无门槛限制</div>
          <div v-if="scope.row.isFreeOrder==2">服务项限制</div>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="state" :min-width="150">
        <template slot-scope="scope">
          <div v-if="scope.row.state==0">未上架</div>
          <div v-if="scope.row.state==1">已上架</div>
          <div v-if="scope.row.state==2">已下架</div>
        </template>
      </el-table-column>
      <el-table-column label="有效期限（天）" prop="validPeriod" :min-width="150">
      </el-table-column>

      <el-table-column label="创建时间" prop="ctime" :min-width="180"/>

      <el-table-column label="操作" fixed="right" prop="" width="200">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="tablePublishHandle(scope.row)" v-if="scope.row.state == 2 || scope.row.state == 0">上架</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tablePublishDownHandle(scope.row)" v-if="scope.row.state == 1">下架</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="editHandle(scope.row)" v-if="scope.row.state != 1">编辑</el-button>
<!--          <el-button class="handle_btn" type="text" size="mini" @click="copyHandle(scope.row)">复制</el-button>-->
        </template>
      </el-table-column>
    </my-table>
  </div>
</template>

<script>
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        cardTypeList: [
            {
                label: "会员卡",
                value: 1
            },
            {
                label: "超级会员卡",
                value: 2
            }
        ],
        listData: [],
        // 搜索数据
        searchData: {
          cardName: "", // 任务名称
          cardType: "" // 任务类型
        },
        listLoading: false //列表loading
      };
    },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num) {
      this.getPlusTemplateList(params, num ? num : params.pageNum, params.pageSize);
    },
    tablePublishDownHandle(row) {
      let url = 'plusTemplate/plusTemplatePublishDown';
      this.$confirm('是否确认下架？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.listLoading = true;
        this.$store.dispatch(url, {
          plusTemplateCode: row.plusTemplateCode
        }).then(res => {
          if(res.code == 0){
            this.$message.success('下架成功')
            this.$refs.list.init()
          }else{
            this.listLoading = false;
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消下架'
        });
      });
    },
    tablePublishHandle(row) {
      let url = 'plusTemplate/plusTemplatePublish';
      this.$confirm('是否确认上架？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.listLoading = true;
        this.$store.dispatch(url, {
          plusTemplateCode: row.plusTemplateCode
        }).then(res => {
          if(res.code == 0){
            this.$message.success('上架成功')
            this.$refs.list.init()
          }else{
            this.listLoading = false;
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消上架'
        });
      });
    },
    editHandle(row) {
      this.$router.push(`/plusCard/template/edit/` + row.plusTemplateCode)
    },
    copyHandle(row) {
      this.$router.push(`/plusCard/template/edit/` + row.plusTemplateCode)
    },
    // 跳转详情
    gotoDetail(row){
      this.$router.push(`/plusCard/template/view/` + row.plusTemplateCode)
    },

    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        cardName: "", // 任务名称
        cardType: "" // 任务类型
      }
    },
    // 获取列表
    getPlusTemplateList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('plusTemplate/getPlusTemplatePageList',{
        ...this.searchData,
        pageNum,
        pageSize
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
